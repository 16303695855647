import { __decorate } from "tslib";
import { Vue, Component, Inject } from 'vue-property-decorator';
import RetentionPolicySettings from '@/components/settings/RetentionPolicySettings.vue';
import SettingsApi from '@/services/api/settings.api';
let SettingsPage = class SettingsPage extends Vue {
    constructor() {
        super(...arguments);
        this.settings = null;
    }
    get retentionPolicy() {
        return this.settings?.retentionPolicy || null;
    }
    mounted() {
        this.fetchSettings();
    }
    async fetchSettings() {
        try {
            this.settings = await SettingsApi.get(this.organizationId);
        }
        catch (error) {
            this.$toast.error(error.message);
        }
    }
};
__decorate([
    Inject()
], SettingsPage.prototype, "organizationId", void 0);
SettingsPage = __decorate([
    Component({
        components: { RetentionPolicySettings },
    })
], SettingsPage);
export default SettingsPage;
