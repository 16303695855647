import { __decorate } from "tslib";
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SettingsApi from '@/services/api/settings.api';
let RetentionPolicySettings = class RetentionPolicySettings extends Vue {
    constructor() {
        super(...arguments);
        this.POLICY_NAMES = {
            full: 'Full backup',
            template: 'Template backup',
            incremental: 'Incremental backup',
        };
        this.policy = {
            full: null,
            template: null,
            incremental: null,
        };
        this.loading = false;
    }
    get policyKeys() {
        return Object.keys(this.POLICY_NAMES);
    }
    onRetentionPolicyChange(value) {
        if (value) {
            this.policy = { ...value };
        }
    }
    togglePolicy(name) {
        if (this.policy) {
            this.setPolicyValue(name, this.policy[name] ? null : 1);
        }
    }
    // eslint-disable-next-line class-methods-use-this
    toNumber(value) {
        return parseInt(value, 10) || 1;
    }
    setPolicyValue(name, value) {
        if (this.policy) {
            this.$set(this.policy, name, value);
        }
    }
    async update() {
        if (!this.policy) {
            return;
        }
        try {
            this.loading = true;
            const { message } = await SettingsApi.updateRetentionPolicy(this.organizationId, this.policy);
            this.$toast.success(message);
        }
        catch (error) {
            this.$toast.error(error.message);
        }
        finally {
            this.loading = false;
        }
    }
};
__decorate([
    Prop({
        default: null,
    })
], RetentionPolicySettings.prototype, "retentionPolicy", void 0);
__decorate([
    Prop()
], RetentionPolicySettings.prototype, "organizationId", void 0);
__decorate([
    Watch('retentionPolicy', { immediate: true, deep: true })
], RetentionPolicySettings.prototype, "onRetentionPolicyChange", null);
RetentionPolicySettings = __decorate([
    Component({})
], RetentionPolicySettings);
export default RetentionPolicySettings;
