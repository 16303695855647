import request from '@/request';
export default class SettingsApi {
    static get(organizationId) {
        return request.get(`organizations/${organizationId}/settings`).then(({ data }) => data);
    }
    static updateRetentionPolicy(organizationId, policyBody) {
        return request
            .put(`organizations/${organizationId}/settings/retention-policy`, policyBody)
            .then(({ data }) => data);
    }
}
